<template>
<div id="shoreMsg" class="set-notice el-content">
	<a-form :label-col="{span:3}" :wrapper-col="{span:12}">
		<a-tabs v-model:activeKey="active" @change="getSetData">
			<a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
				<div v-if="item.key == 1">
					<a-card title="基本设置" class="mb12">
						<a-form-item label="是否开启短信提醒">
							<a-radio-group v-model:value="short.note_is_open">
								<a-radio :value="'1'">开启</a-radio>
								<a-radio :value="'0'">关闭</a-radio>
							</a-radio-group>
						</a-form-item>
						<template v-if="short.note_is_open == 1">
							<a-form-item label="短信通道">
								<a-radio-group v-model:value="short.isKdSms">
									<a-radio :value="'0'">阿里云短信</a-radio>
									<a-radio :value="'1'">坤典短信</a-radio>
									<a-radio :value="'2'" v-if="$store.state.modulesPerm.sass == 1">SASS短信
										<span v-if="programInfo" style="color: rgb(244 153 31);">
											（短信剩余{{programInfo.sms_num}}条）
										</span>
									</a-radio>
								</a-radio-group>
							</a-form-item>
							<a-form-item label="AccessKey ID" v-if="short.isKdSms == 0">
								<a-input v-model:value="short.note_access_key_id"></a-input>
							</a-form-item>
							<a-form-item label="Access Key Secret" v-if="short.isKdSms == 0">
								<a-input v-model:value="short.note_access_key_secret"></a-input>
							</a-form-item>
							<a-form-item label="短信签名" v-if="short.isKdSms !=2">
								<a-input v-model:value="short.note_sign"></a-input>
								<div class="f12 lh16">
									如果使用阿里云短信签名，请填写阿里云审核通过后的签名内容，请勿随便乱填；
									使用坤典短信，二选一填写：坤典科技、智慧农场
								</div>	
							</a-form-item>
							<a-form-item label="新订单通知接收手机号">
								<a-input v-model:value="short.note_new_order_receive_phone"></a-input>
								<div class="f12 lh16">
									多个接收手机号请使用英文逗号“,”分隔开，
									比如：18788888888,18799999999,18788998899
								</div>
							</a-form-item>
						</template>
					</a-card>
					<a-card title="通知配置" class="mb12" v-if="short.isKdSms != 2 && short.note_is_open == 1">
						<a-form-item label="验证码（授权手机号）">
							<a-input v-model:value="short.note_temp_code"></a-input>
							<div class="f12 lh16">主要用于系统需要验证码手机的场景使用；使用坤典短信 请填写：SMS_172765068 </div>
						</a-form-item>
						<a-form-item label="注册密码发送">
							<a-input v-model:value="short.note_temp_password"></a-input>
							<div class="f12 lh16">
								自动注册账户场景，发送用户注册后的随机密码；
								不涉及就可以不用配置，使用坤典短信 请填写：SMS_179160198
							</div>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>商家通知</span>
								<a-tooltip>
									<template #title><div>
										<img src="@/assets/img/note_1.png" alt="" /></div>
									</template>
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="short.note_store_apply_check"></a-input>
							<div class="f12 lh16">通知的商家模板，如果使用坤典短信，请配置：SMS_199590413</div>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>店铺申请通知(管理员)</span>
								<a-tooltip>
									<template #title><div><img src="@/assets/img/note_3.png" alt="" /></div></template>
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="short.note_store_apply_to_manager"></a-input>
							<div class="f12 lh16">多商户入住申请通知管理员的模板信息内容，使用坤典短信，请填写：SMS_199600365</div>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>新订单通知(管理员)</span>
								<a-tooltip>
									<template #title><div><img src="@/assets/img/note-2.png" alt="" /></div></template>
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="short.note_new_order"></a-input>
							<div class="f12 lh16">新订单短信通知模板内容；使用坤典短信 请填写：SMS_199600370</div>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>订单售后处理通知</span>
								<a-tooltip>
									<template #title><div><img src="@/assets/img/note4.png" alt="" /></div></template>
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="short.after_sale_order_temp"></a-input>
							<div class="f12 lh16">订单售后处理通知模板，使用坤典短信，请填写：SMS_199585396</div>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>商家订单售后处理通知</span>
								<a-tooltip>
									<template #title><div><img src="@/assets/img/note5.png" alt="" /></div></template>
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="short.store_after_sale_order_temp"></a-input>
							<div class="f12 lh16">商家订单售后处理模板内容；使用坤典短信 请填写：SMS_200176453</div>
						</a-form-item>
					</a-card>
				</div>
				<div v-if="item.key == 2">
					<com-power-form :form-data="[
						{label:'邮箱提醒',type:'switch',value:'',key:'email_is_open',dataType:'string'},
						{label:'发件人邮箱',type:'text',value:'',key:'email_addresser',dataType:'string'},
						{label:'发件平台名称',type:'text',value:'',key:'email_platform',dataType:'string'},
						{label:'收件人邮箱',type:'text',value:'',key:'email_consignee',dataType:'string'},
						{label:'授权码',type:'text',value:'',key:'email_code',dataType:'string',
							tips:'<a target=_blank href=https://service.mail.qq.com/cgi-bin/help?subtype=1&&no=1001256&&id=28>什么是授权码？</a>'
						},
					]"></com-power-form>
				</div>
				<div v-if="item.key == 3">
					<com-power-form :form-data="[
						{label:'订单自动打印',type:'switch',value:'',key:'print_is_auto',dataType:'string'},
						{label:'飞鹅云账号',type:'text',value:'',key:'print_user',dataType:'string'},
						{label:'飞鹅云UKEY',type:'text',value:'',key:'print_key',dataType:'json'},
						{label:'打印机编号',type:'text',value:'',key:'print_sn',dataType:'string'},
					]"></com-power-form>
				</div>
				<div v-if="item.key == 4">
					<div class="tips">
						<p>温馨提示：</p>
						<!-- <p>1、IM聊天使用的是原生websocket实现的客服会话</p> -->
						<p>1、
							<a href="https://yzf.qq.com/xv/web/static/help/index.html?faqid=190429yMfUzu190429rYfqui" target="__blank">
								腾讯云智服
							</a> 
						</p>
						<p>3、微信小程序原生客服会话仅支持微信小程序，h5和APP不支持，
							即使设置了会话类型为 “微信小程序原生客服会话” 也不会生效</p>
						<p>4、选择 腾讯云智服 + 微信小程序原生客服会话” 
							表示在微信小程序端使用微信小程序原生客服会话，在H5和APP端使用腾讯云智服</p>
					</div>
					<a-form-item label="消息推送">
						<a-button type="primary" @click="pushmessage = true">发送推送消息</a-button>
					</a-form-item>
					<com-power-form :form-data="[
						{label:'客服会话选择',type:'radio',value:'',key:'im_type',dataType:'string',
							options:[
								{value:'1',label:'腾讯云智服'},
								{value:'2',label:'微信小程序原生客服会话'},
								{value:'3',label:'腾讯云智服 + 微信小程序原生客服会话'},
							]
						},
						{label:'腾讯云智服地址',type:'text',value:'',key:'im_server_url',dataType:'string'}
					]"></com-power-form>
				</div>
				<div v-if="item.key == 5">
					<page-service></page-service>
				</div>
			</a-tab-pane>
		</a-tabs>
		<a-form-item :wrapper-col="{offset:3}" v-if="active ==1">
			<a-button type="primary" @click="submitSave">保存</a-button>
		</a-form-item>
	</a-form>

	<!-- 给全部用户发送消息推送 -->
	<a-modal v-model:visible="pushmessage" title="推送消息测试" @ok="sendPushMessage" @cancel="pushmessage = false">
		<a-form :label-col="{span:3}" :wrapper-col="{span:8}">
			<a-form-item label="消息标题">
				<a-input v-model:value="pushmessageForm.title"></a-input>
			</a-form-item>
			<a-form-item label="消息内容">
				<a-input v-model:value="pushmessageForm.content"></a-input>
			</a-form-item>
			<a-form-item label="跳转地址">
				<a-input v-model:value="pushmessageForm.link"></a-input>
			</a-form-item>
		</a-form>
	</a-modal>
</div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import set from "@/api/set.js"
import pageService from "@/components/layout/set/page-service.vue"
import rh from '@/router/routerHandle.js'
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		pageService,
		comPowerForm
	},
	setup(){
		const active = ref("1")
		const _d = reactive({
			tabs:[],
			short:{
				note_sign:'',
				note_temp_code:'',
				note_temp_password:'',
				note_access_key_id:'',
				note_access_key_secret:'',
				note_is_open:'1',
				note_new_order_receive_phone:'',
				note_store_apply_check:'',
				note_store_apply_to_manager:'',
				note_new_order:'',
				after_sale_order_temp:'',
				isKdSms:'0',
				store_after_sale_order_temp:''
			},
			programInfo:JSON.parse(sessionStorage.getItem('__PROGRAMAPP__')),
			pushmessage:false,
			pushmessageForm:{
				title:"",
				content:"",
				link:"",
			}
		})

		_d.tabs = rh.getCuurentTabs("notice")
		if( _d.tabs.length > 0 ) getSetData(_d.tabs[0].key)
        
        function getSetData(_a){
            let keys = []
			active.value = _a
            if( _a == "1" ) keys = Object.keys(_d.short)
            
            set.getFarmSet(keys).then(res=>{
                if(_a == "1" ) _d.short = { ...res }
            })
        }
        function submitSave(){
            let _a = active.value , param = null
            if( _a == "1" ) param = _d.short
            set.setFarmSet(param)
        }

		function sendPushMessage(){
			let res = set.sendPushMessage({..._d.pushmessageForm});
			if( res ) _d.pushmessage = false
		}

		return{
			active,
			...toRefs(_d),
			submitSave,
			getSetData,
			sendPushMessage
		}
	}
}
</script>

<style lang="scss">
</style>
